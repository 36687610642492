import { useTranslation as useTranslate } from 'react-i18next'
import { COOKIES_NAME_LANGUAGE, getCookie, setCookie } from '../utilities/Cookies'
import { createSimpleEmitter } from '../utilities/Emitter'
import { useEffect } from 'react'
import { useAppRouter } from './UseAppRouter'
import { useGetAppRealPath, useServerService } from '../components/FreecProvider'
import { getIsServer } from '../utilities/SSR'
import { translateRouterHref } from '../components/share/AppLink'
import { DEFAULT_LOCALE } from '../utilities/Constant'
import { useCurrentUser } from './CurrentUserHooks'
import { createOneTimeRun } from '../utilities/SingleInvokeFuction'
import { getPathSpecificLanguage, getTranslateRouterHref } from 'src/@legacy/@optimal-page/hooks/useTranslateLink'

const singleInvoice = createOneTimeRun()
export type TraslateFuncType = (message: string, parrams?: { [key: string]: string | number } | string, options?: any) => string
const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter()
export const useTranslation = (
  onLanguageChange?: () => Promise<any>
): {
  currentLanguage: string
  translate: TraslateFuncType
  changeLanguage: (code: string) => void
  switchLanguage: (swicher: { en: string; vi: string }) => string
  fromNumberToCode: (id: number) => string
  currentLanguageNumber: number
} => {
  const router = useAppRouter()
  const { t } = useTranslate()
  const getAppRealPath = useGetAppRealPath()
  const serverService = useServerService()
  const realPath = router?.asPath ? router.asPath.split('?')[0] : getAppRealPath()

  const locale =
    getPathSpecificLanguage(realPath, router.locale) ||
    (getIsServer()
      ? serverService
        ? serverService.getCookie(COOKIES_NAME_LANGUAGE)
        : router?.locale
      : getCookie(COOKIES_NAME_LANGUAGE)) ||
    DEFAULT_LOCALE

  const languageNumberMap = {
    en: 0,
    vi: 1,
    vn: 1
  }

  const numberLanuageMap = {
    0: 'en',
    1: 'vi'
  }
  const [currentUser] = useCurrentUser()

  const changeLanguage = (code) => {
    setCookie(COOKIES_NAME_LANGUAGE, code, 10000)
    // const { href: link, defaultPath } = getTranslateRouterHref(router.asPath.split('?')[0], code)
    // const search = location.search
    // const newLink = search ? `${link}${search}` : link
    router.push(router.asPath, undefined, { locale: code })
    emitAll()
    // if (!defaultPath) {
    //   router.push(newLink, newLink, { locale: code })
    //   emitAll()
    // } else {
    //   debugger
    //   router.push(defaultPath, newLink, { locale: code })
    //   emitAll()
    // }
  }
  useEffect(() => {
    if (currentUser?.profile?.language !== undefined && !getCookie(COOKIES_NAME_LANGUAGE)) {
      singleInvoice(() => changeLanguage(numberLanuageMap[currentUser?.profile?.language]))
    }
  }, [currentUser?.profile?.language])
  useEffect(() => {
    if (onLanguageChange) {
      regiterCallback(onLanguageChange)
    }
    return () => {
      if (onLanguageChange) {
        unregisterCallBack(onLanguageChange)
      }
    }
  }, [])
  return {
    currentLanguage: locale,
    translate: t,
    fromNumberToCode: (id: number) => numberLanuageMap[id],
    changeLanguage,
    switchLanguage: (swicher: { en: string; vi: string }) => {
      return locale === 'vi' ? swicher.vi : swicher.en
    },
    currentLanguageNumber: languageNumberMap[locale]
  }
}
